.pricing-card {
    border-radius: 25px;
    margin: 20px;
    width: 320px;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 30px;
    overflow: hidden;
}

.pricing-card.theme-default {
    background-color: var(--white);
    box-shadow: inset 0 4px 20px rgba(41, 206, 255, 0.5);
}

.pricing-card.theme-yellow {
    background-color: var(--white);
    box-shadow: 0 5px 20px rgba(255, 255, 41, 0.5);
}

.pricing-card.theme-yellow.dark-bg {
    background-color: var(--primary-dark);
}

.pricing-card.theme-blue {
    background-color: var(--white);
    box-shadow: 0 5px 20px rgba(41, 206, 255, 0.5);
}

.pricing-card.theme-blue.dark-bg {
    background-color: var(--primary-dark);
}

.pricing-card.theme-yellow .ribbon.save {
    background-color: var(--primary-dark);
    color: var(--primary-yellow);
}

.pricing-card.theme-blue .ribbon.save {
    background-color: var(--primary-dark);
    color: var(--primary-blue);
}

.pricing-card.theme-yellow .ribbon.recommended {
    background-color: var(--primary-yellow);
    color: var(--primary-dark);
}

.pricing-card.theme-blue .ribbon.recommended {
    background-color: var(--primary-blue);
    color: var(--white);
}

.pricing-card.dark-bg .plan-type,
.pricing-card.dark-bg .feature-text,
.pricing-card.dark-bg .terms {
    color: var(--white);
}

.pricing-card.theme-yellow.dark-bg .price {
    color: var(--primary-yellow);
}

.pricing-card.theme-blue.dark-bg .price {
    color: var(--primary-blue);
}

.header {
    margin-bottom: 5px;
}

.plan-type {
    font-size: 22px;
    font-weight: 600;
    color: var(--text-primary);
    margin-bottom: 15px;
}

.price-container {
    display: flex;
    align-items: baseline;
}

.price-container .price {
    font-size: 40px;
    font-weight: bold;
    color: var(--text-primary);
}

.sub-price {
    font-size: 16px;
    color: var(--text-primary);
    margin-left: 5px;
}

.divider {
    width: 100%;
    height: 1px;
    margin: 16px 0;
}

.divider-light {
    background-color: var(--primary-dark);
}

.divider-dark {
    background-color: var(--white);
}

.features {
    flex-grow: 1;
    list-style: none;
    padding: 0;
    margin: 5px 0 80px 0;
}

.feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    font-size: 15px;
    color: var(--text-primary);
    line-height: 1.5;
}

.icon {
    margin-right: 10px;
    font-weight: bold;
}

.icon.available {
    color: var(--tick);
}

.icon.unavailable {
    color: var(--cross);
}

.feature-text {
    flex: 1;
}

.card-footer {
    position: absolute;
    bottom: 20px;
    left: 25px;
    right: 25px;
}

.quote-btn {
    width: 100%;
    padding: 15px 0;
    border-radius: 30px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: var(--primary-dark);
    color: var(--white);
}

.pricing-card.dark-bg .quote-btn {
    background-color: var(--white);
    color: var(--primary-dark);
}

.quote-btn:hover {
    transform: translateY(-2px);
}

.pricing-card.dark-bg .quote-btn:hover {
    background-color: var(--white);
}

.terms {
    text-align: left;
    font-size: 12px;
    color: var(--text-primary);
    margin-bottom: 20px;
    font-style: italic;
    text-decoration: underline;
    margin: 25px 0;
}

.ribbon {
    position: absolute;
    top: 25px;
    right: -50px;
    width: 200px;
    padding: 8px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    transform: rotate(45deg);
    transform-origin: center;
}

.ribbon.save.more {
    background-color: var(--primary-dark);
    color: var(--white);
}

.ribbon.recommended {
    background-color: var(--primary-blue);
    color: var(--white);
}

@media (max-width: 1400px) {
    .pricing-card {
        border-radius: 25px;
        margin: 0px;
        width: 260px;
        min-height: 560px;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px;
        overflow: hidden;
    }
}

@media (max-width: 768px) {
    .pricing-card {
        width: 90%;
        max-width: 280px;
        padding: 20px;
        min-height: 480px;
    }

    .plan-type {
        font-size: 15px;
        margin-bottom: 5px;
    }

    .price-container .price {
        font-size: 24px;
    }

    .sub-price {
        font-size: 14px;
        color: var(--text-primary);
        margin-left: 5px;
    }

    .divider {
        margin: 10px 0;
    }

    .feature-item {
        font-size: 14px;
    }

    .quote-btn {
        font-size: 14px;
        transition: all 0.3s ease;
    }

    .features {
        margin: 0;
    }
}