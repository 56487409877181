/* .intro-bg {
    width: 100%;
    background: url('../../../asset/solutions/Vending Machine/drink&snack/bg.png') no-repeat center;
    background-size: cover;
    margin: 50px 0;
    height: 200vh;
}

.title-container {
    padding-top: 13rem;

}

.intro-title {
    font-size: 43px;
    font-weight: bold;
    text-align: center;
    color: #000000;
}

.intro-small-title {
    font-size: 24px;
    text-align: center;
    color: #000000;
} */

/* General Layout */
/* .vending-machine-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    justify-content: center;
    align-items: center;
}

.vending-machine-image {
    flex: 1;
    text-align: center;
    max-width: 500px;
}

.vending-machine-image img {
    width: 100%;
    height: auto;
}

.vending-machine-specs {
    flex: 1;
    max-width: 500px;
    background-color: #1890ff;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
} */

/* General Table Styling */
/* .specs-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
} */

/* Table Rows */
/* .specs-row {
    display: table-row;
}

.specs-row:nth-child(even) {
    background-color: #01162F;
}

.specs-row:nth-child(odd) {
    background-color: #0078d7;
} */


/* Table Cells */
/* .specs-cell {
    display: table-cell;
    padding: 10px 15px;
    color: white;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.specs-cell:last-child {
    text-align: right;
} */

/* Feature Component Start */

/* .feature-title-container {
    border: 2px solid #00162F;
    border-radius: 50px;
    width: 190px;
    text-align: center;
    margin: auto;
    padding: 10px;
}

.feature-title {
    font-size: 20px;
}

.feature-container {
    height: 100vh;
    background-size: cover;
    background: url('../../../asset/solutions/Vending Machine/features/VMall Radial Diagram.png') no-repeat center;
}

.fimg {
    width: 25%;
    height: 25%;
} */


/* Feature Component End */



/* .specs-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.specs-table tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.specs-table td {
    padding: 10px;
    font-size: 16px;
    color: white;
}

.spec-feature {
    font-weight: bold;
    text-align: left;
}

.spec-value {
    text-align: left;
} */


/* .table-container {
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    margin-left: 20px;
    width: 100%;
    max-width: 500px;

}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

th,
td {
    padding: 15px;
    text-align: left;
    font-size: 16px;
    border-bottom: 2px solid #ffffff;
}

th {
    background-color: #00bfff;
    color: #01162F;
    width: 40%;
}

td {
    background-color: #01162F;
    color: white;
    width: 60%;
}

tr:last-child th,
tr:last-child td {
    border-bottom: none;
}

.table-container table {
    border-radius: 10px;
    overflow: hidden;
}

.intro-img-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.left-img-container {
    width: 50%;
}

.left-img {
    width: 50%;
    height: 100%;
    align-items: center;
}

.table-container {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    width: 50%;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}

th,
td {
    padding: 15px;
    text-align: left;
    font-size: 16px;
}

th {
    background-color: #00bfff;
    color: white;
    width: 30%;
}

td {
    background-color: #002b50;
    color: white;
} */

.coming-title {
    padding-top: 13rem;
    text-align: center;
}