.solution-header {
    background-image: url('../../asset/solutions/Bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 100%;
    width: 100%;
    height: 380px;
    background-color: rgb(0, 22, 47);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: visible;
    padding-top: 6rem;
    z-index: 1;
}

.solution-header>div>label:first-of-type {
    font-weight: bolder;
    font-size: 2.8rem;
    z-index: 1;
}

.solution-header>div>label:last-of-type {
    font-size: 2rem;
    z-index: 1;
}

.solution-header-content {
    bottom: -12rem !important;
    position: absolute;
    max-width: 95%;
    margin: 0 auto;
    overflow: auto;
    gap: 1.3%;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-inline: 4rem;
    -webkit-mask:
        linear-gradient(to left, rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(to right, rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 0) 100%);
    mask: linear-gradient(to left, rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(to right, rgba(0, 0, 0, 1) 98%, rgba(0, 0, 0, 0) 100%);
    -webkit-mask-composite: source-in;
    mask-composite: intersect;
}

.solution-header-item {
    max-width: 310px;
    min-width: 200px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    outline: none;
    border: none;
}

.solution-header-item-label-wrapper label {
    color: black;
    font-size: 18px !important;
    user-select: none !important;
    pointer-events: none;
    font-weight: bold;
}

.solution-header-item-label-wrapper-hide label {
    color: #B6BDC3;
    font-size: 18px !important;
    user-select: none !important;
    pointer-events: none;
    font-weight: bold;
}

.solution-header-item-label-wrapper-hide,
.solution-header-item-label-wrapper {
    max-height: 56px;
    min-height: 56px;
    height: 100%;
    padding-top: 1rem;
    user-select: none;
}

.solution-header-item img {
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
}

.solution-header-item:first-of-type {
    padding-left: 2rem;
}

.solution-header-item:last-of-type {
    padding-right: 1.5rem;
}

.solution-header::-webkit-scrollbar,
.solution-header-content::-webkit-scrollbar {
    display: none;
}

@media(max-width: 1450px) {
    .solution-header-content {
        bottom: -11rem !important;
    }
}

@media(max-width: 1400px) {
    .solution-header-content {
        bottom: -10.5rem !important;
    }
}

@media(max-width: 1190px) {

    .solution-header-content {
        bottom: -9rem !important;
    }
}

@media(max-width: 1120px) {
    .solution-header-content {
        bottom: -8.5rem !important;
    }

    .solution-header {
        height: 320px;
    }
}

@media(max-width: 880px) {
    .solution-header-content {
        gap: 3.5%;
    }

    .solution-header-item:first-of-type {
        padding-left: 8rem;
    }
}

@media(max-width: 842px) {
    .solution-header-item:first-of-type {
        padding-left: 9rem;
    }
}

@media(max-width: 822px) {
    .solution-header-item:first-of-type {
        padding-left: 10rem;
    }
}

@media(max-width: 802px) {
    .solution-header-item:first-of-type {
        padding-left: 11rem;
    }
}

@media(max-width: 782px) {
    .solution-header-item:first-of-type {
        padding-left: 12rem;
    }
}

@media(max-width: 762px) {
    .solution-header-item:first-of-type {
        padding-left: 13rem;
    }
}

@media(max-width: 742px) {
    .solution-header-item:first-of-type {
        padding-left: 14rem;
    }
}

@media(max-width: 722px) {
    .solution-header-item:first-of-type {
        padding-left: 15rem;
    }
}

@media(max-width: 702px) {
    .solution-header-item:first-of-type {
        padding-left: 16rem;
    }
}

@media(max-width: 682px) {
    .solution-header-item:first-of-type {
        padding-left: 17rem;
    }
}

@media(max-width: 662px) {
    .solution-header-item:first-of-type {
        padding-left: 18rem;
    }
}

@media(max-width: 642px) {
    .solution-header-item:first-of-type {
        padding-left: 19rem;
    }
}

@media(max-width: 622px) {
    .solution-header-item:first-of-type {
        padding-left: 20rem;
    }
}

@media(max-width: 602px) {
    .solution-header-item:first-of-type {
        padding-left: 21rem;
    }
}

@media(max-width: 582px) {
    .solution-header-item:first-of-type {
        padding-left: 22rem;
    }
}

@media(max-width: 562px) {
    .solution-header-item:first-of-type {
        padding-left: 23rem;
    }
}

@media(max-width: 542px) {
    .solution-header-item:first-of-type {
        padding-left: 24rem;
    }
}

@media(max-width: 522px) {
    .solution-header-item:first-of-type {
        padding-left: 25rem;
    }
}

@media(max-width: 502px) {
    .solution-header-item:first-of-type {
        padding-left: 26rem;
    }

    .solution-header {
        height: 300px;
    }
}

@media(max-width: 482px) {
    .solution-header-item:first-of-type {
        padding-left: 27rem;
    }
}

@media(max-width: 462px) {
    .solution-header-item:first-of-type {
        padding-left: 28rem;
    }
}

@media(max-width: 442px) {
    .solution-header-item:first-of-type {
        padding-left: 29rem;
    }
}

@media(max-width: 422px) {
    .solution-header-item:first-of-type {
        padding-left: 31rem;
    }
}

@media(max-width: 402px) {
    .solution-header-item:first-of-type {
        padding-left: 32rem;
    }
}

@media(max-width: 382px) {
    .solution-header-item:first-of-type {
        padding-left: 33rem;
    }
}

@media(max-width: 362px) {
    .solution-header-item:first-of-type {
        padding-left: 34rem;
    }
}

@media(max-width: 342px) {
    .solution-header-item:first-of-type {
        padding-left: 35rem;
    }
}

@media(max-width: 322px) {
    .solution-header-item:first-of-type {
        padding-left: 36rem;
    }
}

@media(max-width: 302px) {
    .solution-header-item:first-of-type {
        padding-left: 37rem;
    }

    .solution-header {
        height: 330px;
    }
}

@media(max-width: 282px) {
    .solution-header-item:first-of-type {
        padding-left: 38rem;
    }
}

@media(max-width: 262px) {
    .solution-header-item:first-of-type {
        padding-left: 39rem;
    }
}

@media(max-width:242px) {
    .solution-header-item:first-of-type {
        padding-left: 40rem;
    }
}

@media(max-width: 222px) {
    .solution-header-item:first-of-type {
        padding-left: 41rem;
    }
}

@media(max-width: 202px) {
    .solution-header-item:first-of-type {
        padding-left: 42rem;
    }
}