.home-1-wrapper {
    max-width: 100%;
    width: 100%;
    height: 100%;
    background-color: transparent;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;

    background-image: url('../../asset/homepage/top_bg/motto_top.png'); /* Path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.home-1-wrapper label:first-of-type {
    font-weight: bolder;
}

.home-2-wrapper {
    width: 100%;
    height: 100%;
    background-image: url('../../asset/homepage/top_bg/motto_mid.png'); /* Path to your image */
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 4;
}

.home-3-wrapper {
    width: 100%;
    height: 100%;

    background-image: url('../../asset/homepage/top_bg/motto_bottom.png'); /* Path to your image */
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
}

.video-banner{
    min-height: 95%;
    max-width: 80%;
    width: 100%;
    height: 100%;
    background-color: #00162f;
    margin: 0 auto;
    position: relative;
    width: 80%;
    height: 800px;
    background-color: #00162f;
    border-radius: 20px;
    box-shadow:
        -20px -20px 30px -10px rgba(105, 112, 51, 0.8),
        -20px 20px 30px -10px rgba(114, 142, 116, 0.8),
        20px -20px 30px -10px rgba(80, 108, 103, 0.8),
        20px 20px 30px -10px rgba(76, 212, 250, 0.8),
        0px 20px 30px rgba(83, 125, 130, 0.6),
        0px -20px 30px rgba(96, 113, 99, 0.6);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}



.line1,
.line2,
.line3 {
  display: block;
}



/* Full-size video banner */
.video-banner {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

/* Full-screen video */
.banner-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Video controls */
.video-controls {
    position: absolute;
    bottom: 2%;
    left: 8%;
    transform: translateX(-50%);
    display: flex;
    gap: 0.5rem;
    z-index: 10;
}

.video-controls button {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.8rem;
}

.video-controls button:hover {
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
}



