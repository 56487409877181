.vending-marketing {
    color: var(--primary-dark);
    font-size: 18px;
    text-align: left;
    overflow: hidden;
}

.vending-marketing-content {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.intro-diagram {
    margin-top: 10vh;
    width: 100%;
    height: auto;
}

.intro-section {
    width: 100%;
    background: url('../../../asset/solutions/vending_marketing/intro/Service_VM.png') no-repeat center;
    background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 80px 0;
    overflow-x: hidden;
}

.package-section {
    width: 100%;
    background: url('../../../asset/solutions/vending_marketing/pricing/bg.png') no-repeat center;
    background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    overflow-x: hidden;
}

.plan-button {
    background: transparent;
    border: 2px solid var(--white);
    color: var(--white);
    padding: 15px 60px;
    border-radius: 30px;
    margin: 50px 0;
    font-size: 20px;
}

.intro-section .intro-text,
.plan-text {
    width: 70%;
    left: 15%;
    margin-top: 20px;
}

.sales-section {
    background-image: url('../../../asset/solutions/vending_marketing/pricing/sales/sales.png');
}

.branding-section {
    background-image: url('../../../asset/solutions/vending_marketing/pricing/branding/branding.png');
}

.sales-section,
.branding-section {
    position: relative;
    width: 80%;
    padding: 60px 0;
    margin-bottom: 40px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    justify-self: center;
}

.sales-cards-container,
.branding-cards-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 20px;
    justify-content: space-evenly;
    left: 2%;
}

.marketing-cards-container {
    left: 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    padding: 20px;
    justify-content: space-evenly;
}

.switch-button {
    background: var(--white);
    border-radius: 30px;
    padding: 5px;
    display: inline-flex;
    margin-top: 60px;
    margin-bottom: 10px;
}

.switch-option {
    padding: 8px 40px;
    border: none;
    background: none;
    color: var(--primary-dark);
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;
}

.switch-option.active {
    color: var(--white);
    background: var(--primary-dark);
}

.blend-image {
    width: 20%;
}

.mobile-view {
    display: none;
}

.desktop-view {
    display: block;
    width: 100%;
}

@media (max-width: 1400px) {

    .sales-section,
    .branding-section {
        width: 90%;
    }
}

@media (max-width: 1200px) {

    .sales-section,
    .branding-section {
        width: 95%;
    }
}

@media (max-width: 1100px) {
    .mobile-view {
        display: block;
        width: 100%;
    }

    .desktop-view {
        display: none;
    }

    .package-section {
        width: 100%;
        background: url('../../../asset/solutions/vending_marketing/pricing/bg.png') var(--primary-dark);
        background-size: contain;
        overflow-x: hidden;
        padding: 20px 0px;
        padding-bottom: 70px;
    }

    .package-button {
        padding: 15px 60px;
        border-radius: 30px;
        margin: 50px 0;
        color: var(--white);
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
        font-size: 18px;
    }

    .sales-button {
        background: linear-gradient(135deg, var(--primary-blue) 0%, var(--primary-dark) 100%);
        box-shadow: 0 0px 30px rgba(41, 206, 255, 0.5);
    }

    .branding-button {
        background: linear-gradient(135deg, var(--primary-yellow) 0%, var(--primary-dark) 100%);
        box-shadow: 0 0px 30px rgba(255, 255, 41, 0.5);
    }

    .button-icon {
        width: 25px;
        height: 25px;
        margin-left: 5px;
    }

    .package-section .swiper {
        width: 100%;
        margin: 0 auto;
        z-index: 0;
        padding-bottom: 40px;
    }

    .package-section .swiper-slide {
        width: 90% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 280px;
    }

    .sales-cards-container,
    .branding-cards-container,
    .marketing-cards-container {
        padding: 0;
    }

    .switch-button {
        margin-bottom: 20px;
    }
}

@media (max-width: 1000px) {
    .vending-marketing {
        font-size: 15px;
    }

    .intro-diagram {
        margin-top: 20vh;
    }

    .plan-button {
        padding: 10px 20px;
        margin-bottom: 0px;
        font-size: 15px;
    }

    .sales-section,
    .branding-section {
        position: relative;
        width: 100%;
        padding: 60px 0;
        background-image: none;
    }

    .vending-marketing-text,
    .plan-text {
        width: 80%;
    }

    .intro-section {
        width: 100%;
        background: url('../../../asset/solutions/vending_marketing/intro/Service_VM.png') var(--primary-dark);
        background-size: contain;
        overflow-x: hidden;
        padding: 0px;
        padding-top: 20px
    }
}