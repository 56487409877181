.partner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-image: url('../../asset/homepage/USP_partners/bg/USP_partners.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    height: auto;
    overflow: visible;
    padding-top: 9rem;
}

.partner-list {
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
}

.partner-item {
    perspective: 1000px;
    max-width: 250px;
    width: 100%;
    margin-block: 2%;
    cursor: pointer;
    user-select: none;
    max-height: 300px;
}

.partner-item-inner {
    position: relative;
    width: 100%;
    max-width: 250px;
    max-height: 300px;
    min-height: 300px;
    height: 100%;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;
    border-radius: 15px;
}

.partner-item:hover .partner-item-inner {
    max-width: 250px;
    max-height: 300px;
    min-height: 300px;
    height: 100%;
    width: 100%;
    transform: rotateY(180deg);
}

.partner-item-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.partner-item-front,
.partner-item-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.partner-item-back {
    max-width: 250px;
    max-height: 300px;
    min-height: 300px;
    height: 100%;
    width: 100%;
    border: 1px solid white;
    background-color: #01162f69;
    color: #fff;
    transform: rotateY(180deg);
    box-shadow:
        -4px -4px 6px rgba(255, 255, 255, 0.5),
        4px 4px 6px rgba(255, 255, 255, 0.5);
}

.partner-item-back label {
    padding: 1rem;
    font-weight: normal;
}

.partner-sample-list {
    padding-bottom: 5rem;
    position: relative;
    max-width: 75%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin: 0 auto;
    box-sizing: border-box;
    -webkit-mask:
        linear-gradient(to left, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
    mask: linear-gradient(to left, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%);
    -webkit-mask-composite: source-in;
    mask-composite: intersect;
}

.partner-sample-list::-webkit-scrollbar {
    display: none;
}

.partner-sample-list-inner img {
    max-width: 270px;
    flex: 0 0 auto;
    flex-shrink: 0;
    user-select: none;
    pointer-events: none;
    object-fit: contain;
    margin-inline: 1.4rem;
}

.partner-sample-list-inner {
    gap: 1%;
    width: fit-content;
    display: flex;
    animation-name: infiniteScroll;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes infiniteScroll {
    from {
        transform: translateX(0)
    }

    to {
        transform: translateX(calc(0px - 64%));
    }
}

.partner-sample-list::-webkit-scrollbar {
    display: none;
}

.partner-sample-list {
    -ms-overflow-style: none;
    scrollbar-width: none;
}