.about-page {
    width: 100%;
    overflow-x: hidden;
    color: var(--white);
    font-size: 20px;
}

.top-section {
    background: url('../asset/aboutpage/about_us.png') no-repeat center top;
    background-size: 100% 100%;
    text-align: center;
}

.about-button {
    background: transparent;
    border: 2px solid var(--white);
    color: var(--white);
    padding: 15px 60px;
    border-radius: 30px;
    margin-bottom: 20px;
    font-size: 20px;
}

.intro-text {
    max-width: 50%;
    margin: 50px auto;
}

.icons-section {
    position: relative;
    padding: 50px 0;
    margin-bottom: 100px;
}

.icons-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 60%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-item img {
    width: 250px;
    height: auto;
    margin-bottom: 15px;
}

.icons-section::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../asset/aboutpage/light_bg.png') no-repeat center;
    background-size: 85% auto;
    top: calc(60% + 50px);
    transform: translateY(-50%) scale(0);
    z-index: 1;
    opacity: 0;
    animation: fadeInBgWithScale 1.5s ease-out;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
}

.icon-label {
    background: #00162FE5;
    padding: 8px 20px;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 20px rgba(221, 221, 221, 0.4)) drop-shadow(2px 2px 15px rgba(255, 251, 41, 0.5));
    position: relative;
}

.buttons-section {
    width: 40%;
    margin-left: 60px;
    position: relative;
}

.solution-button {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(10px);
    border: 2px solid var(--white);
    border-radius: 15px;
    color: var(--white);
    padding: 15px 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
}

.solution-button h2 {
    color: var(--white);
    font-weight: 700;
}

.solution-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.solution-button img {
    width: 24px;
    height: 24px;
}

.bottom-section {
    min-height: 80vh;
    padding: 40px;
    position: relative;
    display: flex;
    align-items: center;
    transition: background 0.3s ease;
}

.bg-section-0 {
    background: url('../asset/aboutpage/vending_bg.png') no-repeat center center;
    background-size: 100% auto;
}

.bg-section-1 {
    background: url('../asset/aboutpage/software_bg.png') no-repeat center center;
    background-size: 100% auto;
}

.bg-section-2 {
    background: url('../asset/aboutpage/superapp_bg.png') no-repeat center center;
    background-size: 100% auto;
}

.know-more {
    position: absolute;
    bottom: 120px;
    right: 120px;
    color: var(--gray);
    font-size: 28px;
    opacity: 0.8;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 700;
    font-size: 24px;
}

.know-more::after {
    content: '';
    width: 0;
    height: 1px;
    background: var(--white);
    position: absolute;
    bottom: -2px;
    left: 0;
    transition: width 0.3s ease;
}

.know-more img {
    width: 20px;
    height: auto;
    transform: translateX(0);
    opacity: 0;
    transition: all 0.3s ease;
}

.know-more:hover {
    color: var(--white);
    opacity: 1;
}

.know-more:hover::after {
    width: 100%;
}

.know-more:hover img {
    opacity: 1;
    transform: translateX(5px);
}

.org-chart-overview {
    padding: 40px 20px;
    padding-top: 60px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    background-image: url('../asset/aboutpage/organizational_chart/grainbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.org-chart-header {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../asset/aboutpage/organizational_chart/enhancing.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 450px 80px;
    margin-bottom: 20px;
}

.org-chart-title {
    padding: 15px 30px;
    border: 2px solid var(--primary-dark);
    border-radius: 30px;
    font-size: 20px;
    color: var(--primary-dark);
    background: transparent;
}

.org-chart-overview img {
    width: 75%;
    height: auto;
    display: block;
    margin: 30px auto 0;
    object-fit: contain;
}

.flipbook-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 120vh;
    max-height: 120vh;
    background-color: transparent;
    overflow: hidden;
    background-image: url('../asset/aboutpage/vee_story/flipbook_bg.png');
}

.flipbook-page {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.flipbook-page img {
    width: 100%;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
    object-fit: contain;
}

@media screen and (max-width: 1400px) {
    .about-page {
        font-size: 18px;
    }

    .icon-item img {
        width: 175px;
    }

    .icon-label {
        padding: 8px 10px;
    }

    .buttons-section {
        margin-left: 50px;
    }

    .solution-button {
        padding: 10px 25px;
    }

    .solution-button h2 {
        font-weight: 700;
    }

    .content-box {
        padding: 15px;
    }

    .know-more {
        bottom: 60px;
        right: 60px;
    }

    .flipbook-container {
        min-height: 100vh;
    }
}

@media screen and (max-width: 1200px) {
    .about-page {
        font-size: 16px;
    }

    .icon-label {
        padding: 8px 8px;
    }

    .flipbook-container {
        min-height: 90vh;
    }
}

@media screen and (max-width: 1000px) {
    .about-page {
        font-size: 15px;
    }

    .icons-container {
        max-width: 78%;
    }

    .icons-section {
        margin-bottom: 80px;
    }

    .icons-section::before {
        top: calc(55% + 50px);
        background-size: 95% auto;
    }

    .flipbook-container {
        min-height: 80vh;
    }
}

@media screen and (max-width: 760px) {
    .about-page {
        font-size: 14px;
    }

    .about-button {
        padding: 10px 20px;
        font-size: 16px;
    }

    .intro-text {
        max-width: 90%;
        margin: 30px auto;
        padding: 0 20px;
    }

    .icons-section::before {
        display: none;
    }

    .icons-section {
        padding: 30px 0;
        margin-bottom: 30px;
    }

    .icons-container {
        flex-direction: column;
        align-items: center;
        gap: 40px;
        width: 100%;
    }

    .icon-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .icon-item img {
        width: 150px;
        margin-bottom: 10px;
    }

    .icon-label {
        padding: 6px 15px;
        font-size: 16px;
    }

    .bottom-section {
        min-height: auto;
        padding: 20px;
        flex-direction: column;
    }

    .buttons-section {
        width: 100%;
        margin-left: 0;
    }

    .solution-button {
        padding: 12px 20px;
    }

    .solution-button h2 {
        font-size: 18px;
    }

    .content-box {
        padding: 20px;
    }

    .know-more {
        position: relative;
        bottom: auto;
        right: auto;
        margin-top: 30px;
        justify-content: center;
        font-size: 20px;
    }

    .bg-section-0,
    .bg-section-1,
    .bg-section-2 {
        background: none;
    }

    .icons-section::before {
        background-size: 200% auto;
    }

    .org-chart-overview {
        padding: 20px 0;
    }

    .org-chart-title {
        font-size: 16px;
        padding: 8px 20px;
    }

    .org-chart-overview img {
        width: 95%;
    }

    .flipbook-container {
        min-height: 70vh;
    }
}

.icon-item {
    animation: flyIn 1s ease-out both;
}

.icon-item:nth-child(1) {
    animation-delay: 0s;
}

.icon-item:nth-child(2) {
    animation-delay: 0.3s;
}

.icon-item:nth-child(3) {
    animation-delay: 0.6s;
}

.icons-section.active::before {
    animation: fadeInBgWithScale 1.5s ease-out forwards;
    animation-delay: 1.6s;
}

@keyframes flyIn {
    from {
        transform: translateX(-100vw);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeInBgWithScale {
    0% {
        opacity: 0;
        transform: translateY(-50%) scale(0);
    }

    50% {
        opacity: 1;
        transform: translateY(-50%) scale(1.5);
    }

    100% {
        opacity: 1;
        transform: translateY(-50%) scale(1);
    }
}