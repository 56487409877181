.solution-footer {
    color: var(--white);
    background-color: var(--primary-dark);
    font-size: medium;
    padding: 2rem 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: visible;
}

.solution-footer-info-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    left: 10%;
}

.footer-image-container {
    position: absolute;
    left: 0;
    transform: translateY(-20%);
    z-index: 5;
    flex: 1;
    width: 10%;
}

.footer-image {
    height: auto;
    width: 100%;
    object-fit: contain;
}

.footer-text-container {
    position: absolute;
    left: 10%;
    white-space: nowrap;
}

.footer-text {
    padding-right: 20px;
}

@media (max-width: 768px) {
    .footer-scroll {
        display: flex;
        flex-direction: column;
        animation: infiniteScroll 10s linear infinite;
    }

    .solution-footer .solution-footer-navi-icon {
        display: none;
    }

    .solution-footer-info-wrapper {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        left: 5%;
    }

    .solution-footer-content {
        max-width: 100%;
    }

    .footer-image-container {
        display: none;
    }
}

@keyframes infiniteScroll {
    from {
        transform: translateX(50%);
    }

    to {
        transform: translateX(-100%);
    }
}