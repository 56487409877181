.additional-services {
    padding: 40px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white);
}

.additional-services .button {
    background: transparent;
    border: 2px solid var(--white);
    color: var(--white);
    padding: 15px 60px;
    border-radius: 30px;
    margin-bottom: 50px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
}

.services-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 100px;
    margin: 0 auto;
    width: 100%;
    justify-content: space-around;
}

.service-item {
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    width: calc(50% - 50px);
    margin-bottom: 20px;
}

.bullet {
    margin-right: 8px;
}

.price {
    font-size: 16px;
    font-weight: 500;
    min-width: 65px;
}

.note {
    font-size: 14px;
}

.separator {
    margin: 0 8px;
}

.name {
    font-weight: 500;
    font-size: 16px;
}

.spec {
    font-size: 14px;
    margin-left: 4px;
}

@media (max-width: 1300px) {
    .additional-services .button {
        padding: 10px 20px;
        margin-bottom: 10px;
        font-size: 20px;
        margin-top: 50px;
    }

    .service-item {
        width: 35%;
        margin-top: 15px;
        display: flex;
        flex-direction: column-reverse;
    }

    .separator {
        display: none;
    }

    .service-main {
        display: flex;
        gap: 4px;
    }

    .service-details {
        display: flex;
        gap: 4px;
    }
}

@media (max-width: 768px) {
    .additional-services {
        padding-bottom: 40px;
    }

    .additional-services .button {
        font-size: 15px;
    }

    .service-item {
        width: 70%;
    }
}