.com-footer {
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    position: relative;
    font-family: 'Averta-Regular';
    border: none;
    outline: none;
}

.com-footer-content {
    border: none;
    outline: none;
    padding-top: 20rem;
    width: 100%;
    height: 100%;
    min-height: 100dvh;
    background-image: url('../asset//footer/bg-circle.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.com-footer .title-2 {
    padding-inline: 1rem;
    font-size: 5rem;
}

.com-footer-bottom-content {
    bottom: 0;
    max-width: 85%;
    width: 100%;
    padding-bottom: 2rem;
}

.com-footer-bottom-content-item {
    max-width: 30%;
    width: 100%;
    min-height: 200px;
    word-break: break-all;
}

.com-footer-logo {
    filter: invert(1);
    width: 100%;
    height: 100%;
    max-width: 150px;
    min-width: 150px;
}

.com-footer-bottom-content-item-header {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    min-height: 20px;
    height: auto;
    width: 100%;
    font-weight: bolder;
    position: relative;
    font-size: 20px;
}

.com-footer-bottom-content-item-header label {
    position: absolute;
    top: 0;
}

.com-footer-social-media-icon {
    width: 100%;
    height: 100%;
    max-width: 40px;
    max-height: 40px;
}

@media(max-width: 890px) {
    .com-footer .title-1 {
        font-size: 2.5rem;
    }

    .com-footer .title-2 {
        font-size: 4rem;
    }

    .com-footer .title-3 {
        font-size: 1.2rem !important;
    }

    .com-footer-bottom-content {
        bottom: 0;
        max-width: 100%;
        width: 100%;
        top: 48%;

    }

    .com-footer-bottom-content>div {
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        text-align: left;
    }

    .com-footer-bottom-content-item {
        max-width: 80% !important;
        width: 100%;
        min-height: 150px;
    }

    .com-footer-content,
    .com-footer {
        min-height: 140dvh;
    }

    .com-footer-content>div {
        padding-top: 5% !important;
    }
}

@media(max-width: 706px) {
    .com-footer .title-1 {
        font-size: 2rem;
    }

    .com-footer .title-2 {
        font-size: 3rem;
    }

    .com-footer .title-3 {
        font-size: 1.1rem !important;
    }
}

@media(max-width: 355px) {
    .com-footer .title-1 {
        font-size: 1.8rem;
    }

    .com-footer .title-2 {
        font-size: 2.5rem;
    }

    .com-footer .title-3 {
        font-size: 1rem !important;
    }
}

@media(max-width: 302px) {
    .com-footer .title-1 {
        font-size: 1.7rem;
    }

    .com-footer .title-2 {
        font-size: 2.3rem;
    }

    .com-footer .title-3 {
        font-size: .9rem !important;
    }
}

@media(max-height: 826px) {

    .com-footer-content,
    .com-footer {
        min-height: 120dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 727px) {

    .com-footer-content,
    .com-footer {
        min-height: 130dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 640px) {

    .com-footer-content,
    .com-footer {
        min-height: 150dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 575px) {

    .com-footer-content,
    .com-footer {
        min-height: 170dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 500px) {

    .com-footer-content,
    .com-footer {
        min-height: 250dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 430px) {

    .com-footer-content,
    .com-footer {
        min-height: 280dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 390px) {

    .com-footer-content,
    .com-footer {
        min-height: 350dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 330px) {

    .com-footer-content,
    .com-footer {
        min-height: 290dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 290px) {

    .com-footer-content,
    .com-footer {
        min-height: 320dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 250px) {

    .com-footer-content,
    .com-footer {
        min-height: 480dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 200px) {

    .com-footer-content,
    .com-footer {
        min-height: 550dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 191px) {

    .com-footer-content,
    .com-footer {
        min-height: 450dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 775px) and (max-width: 546px) {

    .com-footer-content,
    .com-footer {
        min-height: 150dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 670px) and (max-width: 546px) {

    .com-footer-content,
    .com-footer {
        min-height: 190dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 620px) and (max-width: 546px) {

    .com-footer-content,
    .com-footer {
        min-height: 190dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 490px) and (max-width: 546px) {

    .com-footer-content,
    .com-footer {
        min-height: 230dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 400px) and (max-width: 546px) {

    .com-footer-content,
    .com-footer {
        min-height: 280dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 330px) and (max-width: 546px) {

    .com-footer-content,
    .com-footer {
        min-height: 330dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}

@media(max-height: 270px) and (max-width: 546px) {

    .com-footer-content,
    .com-footer {
        min-height: 390dvh;
    }

    .com-footer-content>div {
        padding-top: 8% !important;
    }
}