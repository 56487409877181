:root {
    /* Primary Colors */
    --primary-dark: #00162F;
    --primary-blue: #29CEFF;
    --primary-yellow: #FFEF29;
    --primary-pink: #FF2B86;

    /* Text Colors */
    --text-primary: #00162F;
    --text-light: #FFFFFF;

    /* Gradient Colors */
    --gradient-blue: linear-gradient(45deg, #0066FF, #00162F);
    --gradient-yellow: linear-gradient(45deg, #FFB800, #FFF5D6);

    /* check Colors */
    --tick: #6CC000;
    --cross: #C00000;

    --white: #FFFFFF;
    --black: #000000;  
    --gray: #848484;
}