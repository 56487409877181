.floating-input-container {
    position: relative;
    margin: 20px;
}

.floating-input,
.floating-textarea {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    border: 2px solid #fff;
    border-radius: 15px;
    background: transparent;
    color: #fff;
    outline: none;
    box-sizing: border-box;
    resize: none;
}

.floating-input::placeholder {
    color: transparent;
}

.floating-label {
    position: absolute;
    left: 15px;
    top: 45%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #ccc;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.floating-input:focus~.floating-label,
.floating-label.active {
    top: -12px;
    font-size: 14px;
}

@media screen and (max-width: 1030px) {

    .floating-input:focus~.floating-label,
    .floating-label.active {
        top: -12px !important;
        background-color: transparent !important;
        padding: 5px;
    }

    .floating-input-container {
        margin: 15px;
    }

}