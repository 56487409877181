/* CORE SERVICE SECTION */
.core-1-wrapper {
    width: 100%;
    position: relative;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url('../../asset/homepage/vending-bg.png');
    /* Path to your image */
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    min-height: 80dvh;
}

.core-left-content {
    width: 80%;
    padding: 4rem;
    margin-right: 5%;
}

.core-right-graphic {
    width: 30%;
    padding: 4rem;
    position: relative;
    display: flex;
}

.core-1-graphic {
    width: 100%;
    text-align: center;
}

.core-graphic {
    width: 220px;
}

.core-float-graphic {
    width: 500px;
}

.core-left-content .black-content-text {
    color: black;
    transition: color 0.3s ease-in-out;
}

.core-left-content .gray-content-text {
    color: #89A2B7;
    font-size: 1.5rem;
    padding: 0.3rem 0;
    font-family: 'Averta-Regular';
    transition: color 0.3s ease-in-out;
}

.core-graphic-scroll {
    position: absolute;
    z-index: 1;
    top: 74px;
    left: -80px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.core-graphic-scroll.visible {
    opacity: 1;
    transform: translateY(0);
}

.core-graphic-scroll.hidden {
    opacity: 0;
    transform: translateY(50px);
}

.core-graphic-fixed {
    position: sticky;
    padding-top: 40px;
}

.vmall-float-graphic {
    width: 500px;
}

.core-graphic-scroll2 {
    position: absolute;
    z-index: 1;
    top: 101px;
    left: -81px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.core-graphic-scroll2.visible {
    opacity: 1;
    transform: translateY(0);
}

.core-graphic-scroll2.hidden {
    opacity: 0;
    transform: translateY(50px);
}

.core-2-wrapper {
    width: 100%;
    position: relative;
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../asset/homepage/vmall-bg.png');
    /* Path to your image */
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    min-height: 80dvh;
}

/* MOTTO */
.motto-border {
    margin: 0 auto;
    /* Centered */
    border: 2px solid #fff;
    padding: 1.5rem 5.5rem;
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    border-radius: 50px;
    width: 100%;
    margin-top: 11rem;
    color: white;
    flex: 1;
    min-width: 60%;
    max-width: 60%;
}

.motto-border label:last-of-type {
    /* text-align: right; */
    font-weight: bolder;
    margin-left: 1rem;
}

.motto-border div:first-of-type {
    flex: 0.5;
    width: 100%;
    min-width: 45%;
    max-width: 45%;
}

.motto-border div:last-of-type {
    flex: 0.5;
    width: 100%;
    min-width: 55%;
    max-width: 55%;
}

@media(max-width: 1700px) {
    .motto-border {
        padding: .5rem 2.5rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }

    .motto-border div:first-of-type {
        flex: 1;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }

    .motto-border div:last-of-type {
        flex: 1;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        margin: 0;
        margin-top: 1rem;
    }

    .motto-border label {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
        overflow: hidden;
        text-align: center;
        margin: 0 auto !important;
        word-break: break-word;
    }
}