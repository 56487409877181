:root {
    --item-border-radius: 12px;
}

.our-service {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-image: url('../../asset/homepage/our_services/bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    height: auto;
    overflow: visible;
    padding-top: 9rem;
}

.our-service-list {
    gap: 3%;
    padding-inline: 1rem;
    flex-wrap: wrap;
}

.our-service-item {
    border: none;
    outline: none;
    position: relative;
    z-index: 5;
    max-width: 350px;
    min-width: 20%;
    width: 100%;
    margin-block: 3%;
}

.our-service-item {
    border: none;
    outline: none;
    position: relative;
    z-index: 5;
    max-width: 250px;
    width: 100%;
    margin-block: 3%;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: var(--item-border-radius);
}

.our-service-item:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
    border-radius: var(--item-border-radius);
}

/* .our-service-item-image {width: 100%;
    height: 45%;
    border-top-right-radius: var(--item-border-radius);
    border-top-left-radius: var(--item-border-radius);
    overflow: hidden;
    clip-path: ellipse(100% 100% at 50% 0%);
}

.our-service-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.our-service-item-divider-light {
    clip-path: ellipse(100% 100% at 50% 0%);
    width: 100%;
    height: 45%;
    background: linear-gradient(to bottom, rgba(0, 0, 255, 0) 80%, #ffffff 100%);
    position: absolute;
    top: 0;
}

.our-service-item-divider-dark {
    clip-path: ellipse(100% 100% at 50% 0%);
    width: 100%;
    position: absolute;
    top: 240px;
    height: 50px;
    background: linear-gradient(to bottom, rgba(0, 0, 255, 0) 5%, #00162fec 100%);
}

.our-service-item-timeline {
    max-height: 250px;
    min-height: 250px;
    border-bottom-right-radius: var(--item-border-radius);
    border-bottom-left-radius: var(--item-border-radius);
} */