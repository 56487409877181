body::-webkit-scrollbar {
    display: none;
}

.contact-us-container {
    padding: 50px;
    height: 100vh;
    background: url('../../asset/contactUs/contact_bg.png') no-repeat center center fixed;
    background-size: cover;
    color: #ffffff;
    overflow: hidden !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.both-panel {
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100vh;
    width: 80%;
}

.left-panel {
    max-width: 40%;
    padding: 2.5rem;
}

.contact-title-container {
    width: 70%;
}

.title {
    font-family: 'Averta-Regular' !important;
}

.description {
    margin: 20px 0;
    line-height: 1.5;
    width: 55%;
    font-size: 16px;
}

.contact-info {
    list-style: none;
    padding: 0;
}

.contact-info li {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 18px;
}

.icon {
    font-size: 20px;
    margin-right: 10px;
}

.form-panel {
    border: 4px solid #00162F;
    border-radius: 20px;
    width: 500px;
    padding: 1rem;
}

.blur-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #FFFFFF33;
    backdrop-filter: blur(8px);
    z-index: -1;
    border-radius: 20px;
}

.button-container {
    margin-right: 12px;
}

.submit-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #01162F;
    /* Dark blue */
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 25px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-align: center;
    width: 35%;
    padding: 13px;
}

.submit-button:hover {
    background-color: #0d2846;
}

.arrow {
    position: absolute;
    right: 30px;
    opacity: 0;
    transform: translateX(-5px);
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.submit-button:hover .arrow {
    opacity: 1;
    transform: translateX(0);
    font-weight: 600;
}

.service-input-container {
    max-width: 700px;
    padding: 15px;
    margin: 5px 20px;
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
}

.dropdown-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.dropdown-label {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    margin-right: 10px;
}

.service-dropdown {
    flex: 1;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    background: #ffffff;
    border: none;
    border-radius: 20px;
    outline: none;
    appearance: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-textarea {
    width: 100%;
    height: 100px;
    font-size: 14px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.8);
    background: transparent;
    border: none;
    outline: none;
    resize: none;
    overflow-y: auto;
    font-style: normal;
}

.service-textarea::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-style: italic;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
}

@media screen and (max-width: 1030px) {

    /* .blur-background::before {
        background-color: #FFFFFF33;
        transform: translateZ(0);
    } */
    .contact-title-container,
    .description {
        text-align: center;
        width: 100% !important;
    }

    .contact-us-container {
        padding: 1rem !important;
        position: absolute !important;
        height: 135vh;
    }

    .both-panel {
        flex-direction: column !important;
        padding: 1rem !important;
    }

    /* Left Panel */
    .left-panel {
        text-align: left !important;
        padding: 2.7rem 0 2rem 8px !important;
        max-width: 100%;
        width: 100% !important;
    }

    .contact-info li {
        display: flex !important;
        align-items: center !important;
        margin: 0.5rem 0 !important;
    }

    .contact-info .icon img {
        width: 20px !important;
        height: 20px !important;
        margin-right: 0.5rem !important;
    }

    .form-panel {
        width: 100% !important;
        padding: 1rem !important;
        box-sizing: border-box !important;
        position: relative !important;
        backdrop-filter: blur(8px) !important;
    }

    .input-1,
    .input-2 {
        flex-direction: column !important;
        /* Stack inputs vertically */
    }

    .input-1 .floating-input,
    .input-2 .floating-input {
        width: 100% !important;
    }

    .service-input-container {
        display: flex !important;
        flex-direction: column !important;
        gap: 1rem !important;
        backdrop-filter: none !important;
        margin: 5px 15px;
    }

    .service-dropdown {
        width: 100% !important;
        padding: 0.5rem !important;
        font-size: 1rem !important;
    }

    .service-textarea {
        width: 100% !important;
        height: 120px !important;
        font-size: 0.9rem !important;
        padding: 0.5rem !important;
        box-sizing: border-box !important;
    }

    .button-container {
        justify-content: center !important;
        padding: 0 3%;
        margin-right: 0 !important;
    }

    .submit-button {
        width: 100% !important;
        font-size: 1rem !important;
    }

    .submit-button:hover .arrow {
        display: none;
    }
}

@media screen and (min-width:300px) and (max-width: 433px) {

    .dropdown-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .dropdown-label {
        margin: 0 0 10px 5px;
        font-size: 15px;
    }

    .service-dropdown {
        font-size: 14px !important;
    }

    .arrow {
        display: none !important;
    }

    .button-container {
        padding: 0 8%;
        margin-bottom: 1.5rem;
    }

    .contact-title-container,
    .description,
    .contact-info {
        padding: 0 8px;
    }

    .contact-us-container {
        padding: 1rem !important;
        position: absolute !important;
        height: 180vh;
    }

}