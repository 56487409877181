@media (max-width: 1400px) {

    /* Text */
    .black-title {
        font-size: 2.5rem !important;
    }

    .black-big-title {
        font-size: 2.8rem !important;
    }

    .black-bold-title {
        font-size: 2.5rem !important;
    }

    .black-big-text {
        font-size: 2rem !important;
    }

    .black-content-text {
        font-size: 1.4rem !important;
    }

    .white-title {
        font-size: 2.5rem !important;
    }

    .white-big-title {
        font-size: 2.8rem !important;
    }

    .white-bold-title {
        font-size: 2.8rem !important;
    }

    .white-big-text {
        font-size: 2rem !important;
    }

    .white-content-text {
        font-size: 1.4rem !important;
    }

    .gray-content-text {
        font-size: 1.4rem !important;
    }

    /* .motto-border{
        padding: 1rem 4.5rem  !important;
    } */
    .core-left-content {
        padding: 1.5rem !important;
        margin-right: 10% !important;
    }

    .padding-wrapper {
        width: 80% !important;
    }
}

@media (max-width: 1200px) {

    /* Text */
    .black-title {
        font-size: 2.3rem !important;
    }

    .black-big-title {
        font-size: 2.6rem !important;
    }

    .black-bold-title {
        font-size: 2.3rem !important;
    }

    .black-big-text {
        font-size: 1.8rem !important;
    }

    .black-content-text {
        font-size: 1.3rem !important;
    }

    .white-title {
        font-size: 2.3rem !important;
    }

    .white-big-title {
        font-size: 2.6rem !important;
    }

    .white-bold-title {
        font-size: 2.3rem !important;
    }

    .white-big-text {
        font-size: 1.8rem !important;
    }

    .white-content-text {
        font-size: 1.3rem !important;
    }

    .gray-content-text {
        font-size: 1.3rem !important;
    }


    /* .motto-border label:last-of-type{
        margin-left: 0.6rem !important;
    }
    .motto-border{
        padding: 1rem 3.5rem  !important;
    } */
    .video-controls {
        left: 10%;
    }

    .core-left-content {
        font-size: 1.3rem !important;
    }

    .core-graphic {
        width: 180px !important;
    }

    .core-float-graphic {
        width: 430px !important;
    }

    .core-right-graphic {
        padding: 2rem !important;
    }

    .core-graphic-scroll {
        top: 44px !important;
        left: -98px !important;
    }

    .padding-wrapper {
        width: 90% !important;
    }

    .core-left-content {
        width: 70% !important;
    }

    .contact-title-container {
        width: 78% !important;
    }
}

@media (max-width: 1090px) {

    /* Text */
    .black-title {
        font-size: 2.2rem !important;
    }

    .black-big-title {
        font-size: 2.4rem !important;
    }

    .black-bold-title {
        font-size: 2.2rem !important;
    }

    .black-big-text {
        font-size: 1.6rem !important;
    }

    .black-content-text {
        font-size: 1.2rem !important;
    }

    .white-title {
        font-size: 2.2rem !important;
    }

    .white-big-title {
        font-size: 2.4rem !important;
    }

    .white-bold-title {
        font-size: 2.2rem !important;
    }

    .white-big-text {
        font-size: 1.6rem !important;
    }

    .white-content-text {
        font-size: 1.2rem !important;
    }

    .gray-content-text {
        font-size: 1.2rem !important;
    }

    .core-float-graphic {
        width: 400px !important;
    }

    .core-graphic {
        width: 160px !important;
    }
}

@media (max-width: 1024px) {

    /* Text */
    .black-title {
        font-size: 2.1rem !important;
    }

    .black-big-title {
        font-size: 2.2rem !important;
    }

    .black-bold-title {
        font-size: 2.1rem !important;
    }

    .white-title {
        font-size: 2.1rem !important;
    }

    .white-big-title {
        font-size: 2.2rem !important;
    }

    .black-bold-title {
        font-size: 2.1rem !important;
    }

    .page-wrapper {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    /* .motto-border label:last-of-type{
        margin-left: 0.5rem !important;
    }
    .motto-border{
        padding: 0.8rem 3rem  !important;
        margin-top: 7rem !important;
    } */
    .video-controls {
        left: 12%;
    }

}


@media (max-width: 992px) {

    /* Text */
    .black-title {
        font-size: 2rem !important;
    }

    .black-big-title {
        font-size: 2.1rem !important;
    }

    .black-bold-title {
        font-size: 2rem !important;
    }

    .black-big-text {
        font-size: 1.4rem !important;
    }

    .white-title {
        font-size: 2rem !important;
    }

    .white-big-title {
        font-size: 2.1rem !important;
    }

    .white-bold-title {
        font-size: 2rem !important;
    }

    .white-big-text {
        font-size: 1.4rem !important;
    }

    .core-1-wrapper .flex-wrapper {
        flex-direction: column-reverse !important;
    }

    .core-right-graphic {
        width: 90% !important;
        justify-content: flex-end !important;
        margin-bottom: -10rem !important;
    }

    .core-left-content {
        margin-right: 0 !important;
    }

    .core-float-graphic {
        display: none;
    }

    .core-graphic-scroll {
        top: 7px !important;
        right: -60px !important;
    }

    .core-graphic-scroll2 {
        top: 63px !important;
        right: -61px !important;
    }

    .core-graphic-fixed {
        padding-top: 0;
    }

    .core-graphic {
        width: 170px !important;
        margin-right: 1rem;
        display: none;
    }

    .core-2-wrapper .flex-wrapper {
        flex-direction: column-reverse !important;
    }

    .home-1-wrapper {
        padding-top: 6rem !important;
        padding-bottom: 6rem !important;
    }

    .core-2-wrapper {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

    .core-1-wrapper {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }

}

@media (max-width: 768px) {

    /* Text */
    .black-title {
        font-size: 1.9rem !important;
    }

    .black-big-title {
        font-size: 2rem !important;
    }

    .black-bold-title {
        font-size: 1.9rem !important;
    }

    .black-big-text {
        font-size: 1.3rem !important;
    }

    .black-content-text {
        font-size: 1.1rem !important;
    }

    .white-title {
        font-size: 1.9rem !important;
    }

    .white-big-title {
        font-size: 2rem !important;
    }

    .white-bold-title {
        font-size: 1.9rem !important;
    }

    .white-big-text {
        font-size: 1.3rem !important;
    }

    .white-content-text {
        font-size: 1.1rem !important;
    }

    .gray-content-text {
        font-size: 1.1rem !important;
    }

}


@media (max-width: 480px) {

    /* Text */
    .black-title {
        font-size: 1.8rem !important;
    }

    .black-big-title {
        font-size: 1.9rem !important;
    }

    .black-bold-title {
        font-size: 1.9rem !important;
    }

    .black-big-text {
        font-size: 1.2rem !important;
    }

    .black-content-text {
        font-size: 1rem !important;
    }

    .white-title {
        font-size: 1.8rem !important;
    }

    .white-big-title {
        font-size: 1.9rem !important;
    }

    .white-bold-title {
        font-size: 1.8rem !important;
    }

    .white-big-text {
        font-size: 1.2rem !important;
    }

    .white-content-text {
        font-size: 1rem !important;
    }

    .gray-content-text {
        font-size: 1rem !important;
    }
}