@font-face {
    font-family: 'Averta-Regular';
    src: url('../asset/fonts/Averta-Regular.otf') format('truetype');
}

@font-face {
    font-family: 'Averta-Bold';
    src: url('../asset/fonts/Averta-Bold.otf') format('truetype');
}

@font-face {
    font-family: 'Averta-Black';
    src: url('../asset/fonts/Averta-Black.otf') format('truetype');
}


button {
    outline: none;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.h-auto {
    height: auto;
}

.w-auto {
    width: auto;
}

.fit-content {
    width: 100%;
    height: 100%;
}

.invisible {
    display: none !important;
}

.block {
    display: block;
}

.overflow-hidden {
    overflow: hidden;
}

.hide {
    position: relative;
    z-index: -100 !important;
}

/* flex */
.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-evenly {
    justify-content: space-evenly;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.align-item-center {
    align-items: center;
}

.align-item-start {
    align-items: flex-start;
}

.align-item-end {
    align-items: flex-end;
}

.row {
    flex-direction: row;
}

.col {
    flex-direction: column;
}

/* flex */

/* position */
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

/* position */

/* font */
.break-all {
    word-break: break-all;
}

.break-word {
    word-break: break-word;
}

.white-space-normal {
    white-space: normal;
}

/* For single line ellipsis */
.line1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* For two lines with ellipsis */
.line2 {
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

/* For three lines with ellipsis */
.line3 {
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

/* For four lines with ellipsis */
.line4 {
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    white-space: normal;
}

.bold {
    font-weight: bold;
}

.bolder {
    font-weight: bolder;
}

.bold-100 {
    font-weight: 100;
}

.bold-200 {
    font-weight: 200;
}

.bold-300 {
    font-weight: 300;
}

.bold-400 {
    font-weight: 400;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.no-select {
    user-select: none !important;
}

.no-click {
    pointer-events: none !important;
}

/* font */

/* padding */
.p-0 {
    padding: 0rem !important;
}

.p-1 {
    padding: 0.5rem !important;
}

.p-2 {
    padding: 1rem !important;
}

.p-3 {
    padding: 1.5rem !important;
}

.p-4 {
    padding: 2rem !important;
}

.p-5 {
    padding: 2.5rem !important;
}

.p-6 {
    padding: 3rem !important;
}

.p-7 {
    padding: 3.5rem !important;
}

.p-8 {
    padding: 4rem !important;
}

.p-9 {
    padding: 4.5rem !important;
}

.p-10 {
    padding: 5rem !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-1 {
    padding-left: 0.5rem !important;
}

.pl-2 {
    padding-left: 1rem !important;
}

.pl-3 {
    padding-left: 1.5rem !important;
}

.pl-4 {
    padding-left: 2rem !important;
}

.pl-5 {
    padding-left: 2.5rem !important;
}

.pl-6 {
    padding-left: 3rem !important;
}

.pl-7 {
    padding-left: 3.5rem !important;
}

.pl-8 {
    padding-left: 4rem !important;
}

.pl-9 {
    padding-left: 4.5rem !important;
}

.pl-10 {
    padding-left: 5rem !important;
}

.pr-0 {
    padding-right: 0rem !important;
}

.pr-1 {
    padding-right: 0.5rem !important;
}

.pr-2 {
    padding-right: 1rem !important;
}

.pr-3 {
    padding-right: 1.5rem !important;
}

.pr-4 {
    padding-right: 2rem !important;
}

.pr-5 {
    padding-right: 2.5rem !important;
}

.pr-6 {
    padding-right: 3rem !important;
}

.pr-7 {
    padding-right: 3.5rem !important;
}

.pr-8 {
    padding-right: 4rem !important;
}

.pr-9 {
    padding-right: 4.5rem !important;
}

.pr-10 {
    padding-right: 5rem !important;
}

.pt-0 {
    padding-top: 0rem !important;
}

.pt-1 {
    padding-top: 0.5rem !important;
}

.pt-2 {
    padding-top: 1rem !important;
}

.pt-3 {
    padding-top: 1.5rem !important;
}

.pt-4 {
    padding-top: 2rem !important;
}

.pt-5 {
    padding-top: 2.5rem !important;
}

.pt-6 {
    padding-top: 3rem !important;
}

.pt-7 {
    padding-top: 3.5rem !important;
}

.pt-8 {
    padding-top: 4rem !important;
}

.pt-9 {
    padding-top: 4.5rem !important;
}

.pt-10 {
    padding-top: 5rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.5rem !important;
}

.pb-2 {
    padding-bottom: 1rem !important;
}

.pb-3 {
    padding-bottom: 1.5rem !important;
}

.pb-4 {
    padding-bottom: 2rem !important;
}

.pb-5 {
    padding-bottom: 2.5rem !important;
}

.pb-6 {
    padding-bottom: 3rem !important;
}

.pb-7 {
    padding-bottom: 3.5rem !important;
}

.pb-8 {
    padding-bottom: 4rem !important;
}

.pb-9 {
    padding-bottom: 4.5rem !important;
}

.pb-10 {
    padding-bottom: 5rem !important;
}

.pv-0 {
    padding-block: 0 !important;
}

.pv-1 {
    padding-block: 0.5rem !important;
}

.pv-2 {
    padding-block: 1rem !important;
}

.pv-3 {
    padding-block: 1.5rem !important;
}

.pv-4 {
    padding-block: 2rem !important;
}

.pv-5 {
    padding-block: 2.5rem !important;
}

.pv-6 {
    padding-block: 3rem !important;
}

.pv-7 {
    padding-block: 3.5rem !important;
}

.pv-8 {
    padding-block: 4rem !important;
}

.pv-9 {
    padding-block: 4.5rem !important;
}

.pv-10 {
    padding-block: 5rem !important;
}

.ph-0 {
    padding-inline: 0rem !important;
}

.ph-1 {
    padding-inline: 0.5rem !important;
}

.ph-2 {
    padding-inline: 1rem !important;
}

.ph-3 {
    padding-inline: 1.5rem !important;
}

.ph-4 {
    padding-inline: 2rem !important;
}

.ph-5 {
    padding-inline: 2.5rem !important;
}

.ph-6 {
    padding-inline: 3rem !important;
}

.ph-7 {
    padding-inline: 3.5rem !important;
}

.ph-8 {
    padding-inline: 4rem !important;
}

.ph-9 {
    padding-inline: 4.5rem !important;
}

.ph-10 {
    padding-inline: 5rem !important;
}

/* padding */

/* margin */
.m-0 {
    margin: 0rem !important;
}

.m-1 {
    margin: 0.5rem !important;
}

.m-2 {
    margin: 1rem !important;
}

.m-3 {
    margin: 1.5rem !important;
}

.m-4 {
    margin: 2rem !important;
}

.m-5 {
    margin: 2.5rem !important;
}

.m-6 {
    margin: 3rem !important;
}

.m-7 {
    margin: 3.5rem !important;
}

.m-8 {
    margin: 4rem !important;
}

.m-9 {
    margin: 4.5rem !important;
}

.m-10 {
    margin: 5rem !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 0.5rem !important;
}

.ml-2 {
    margin-left: 1rem !important;
}

.ml-3 {
    margin-left: 1.5rem !important;
}

.ml-4 {
    margin-left: 2rem !important;
}

.ml-5 {
    margin-left: 2.5rem !important;
}

.ml-6 {
    margin-left: 3rem !important;
}

.ml-7 {
    margin-left: 3.5rem !important;
}

.ml-8 {
    margin-left: 4rem !important;
}

.ml-9 {
    margin-left: 4.5rem !important;
}

.ml-10 {
    margin-left: 5rem !important;
}

.mr-0 {
    margin-right: 0rem !important;
}

.mr-1 {
    margin-right: 0.5rem !important;
}

.mr-2 {
    margin-right: 1rem !important;
}

.mr-3 {
    margin-right: 1.5rem !important;
}

.mr-4 {
    margin-right: 2rem !important;
}

.mr-5 {
    margin-right: 2.5rem !important;
}

.mr-6 {
    margin-right: 3rem !important;
}

.mr-7 {
    margin-right: 3.5rem !important;
}

.mr-8 {
    margin-right: 4rem !important;
}

.mr-9 {
    margin-right: 4.5rem !important;
}

.mr-10 {
    margin-right: 5rem !important;
}

.mt-0 {
    margin-top: 0rem !important;
}

.mt-1 {
    margin-top: 0.5rem !important;
}

.mt-2 {
    margin-top: 1rem !important;
}

.mt-3 {
    margin-top: 1.5rem !important;
}

.mt-4 {
    margin-top: 2rem !important;
}

.mt-5 {
    margin-top: 2.5rem !important;
}

.mt-6 {
    margin-top: 3rem !important;
}

.mt-7 {
    margin-top: 3.5rem !important;
}

.mt-8 {
    margin-top: 4rem !important;
}

.mt-9 {
    margin-top: 4.5rem !important;
}

.mt-10 {
    margin-top: 5rem !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.5rem !important;
}

.mb-2 {
    margin-bottom: 1rem !important;
}

.mb-3 {
    margin-bottom: 1.5rem !important;
}

.mb-4 {
    margin-bottom: 2rem !important;
}

.mb-5 {
    margin-bottom: 2.5rem !important;
}

.mb-6 {
    margin-bottom: 3rem !important;
}

.mb-7 {
    margin-bottom: 3.5rem !important;
}

.mb-8 {
    margin-bottom: 4rem !important;
}

.mb-9 {
    margin-bottom: 4.5rem !important;
}

.mb-10 {
    margin-bottom: 5rem !important;
}

.mv-0 {
    margin-block: 0 !important;
}

.mv-1 {
    margin-block: 0.5rem !important;
}

.mv-2 {
    margin-block: 1rem !important;
}

.mv-3 {
    margin-block: 1.5rem !important;
}

.mv-4 {
    margin-block: 2rem !important;
}

.mv-5 {
    margin-block: 2.5rem !important;
}

.mv-6 {
    margin-block: 3rem !important;
}

.mv-7 {
    margin-block: 3.5rem !important;
}

.mv-8 {
    margin-block: 4rem !important;
}

.mv-9 {
    margin-block: 4.5rem !important;
}

.mv-10 {
    margin-block: 5rem !important;
}

.mh-0 {
    margin-inline: 0rem !important;
}

.mh-1 {
    margin-inline: 0.5rem !important;
}

.mh-2 {
    margin-inline: 1rem !important;
}

.mh-3 {
    margin-inline: 1.5rem !important;
}

.mh-4 {
    margin-inline: 2rem !important;
}

.mh-5 {
    margin-inline: 2.5rem !important;
}

.mh-6 {
    margin-inline: 3rem !important;
}

.mh-7 {
    margin-inline: 3.5rem !important;
}

.mh-8 {
    margin-inline: 4rem !important;
}

.mh-9 {
    margin-inline: 4.5rem !important;
}

.mh-10 {
    margin-inline: 5rem !important;
}

/* margin */

/* animation */
.fade-in {
    animation: fadeIn .2s ease-in forwards;
}

.fade-out {
    animation: fadeOut .2s ease-out forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.slide-up {
    animation: slideUp 1.5s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}


@keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-20px);
        opacity: 0;
    }
}

/* animation */

button.default {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
}

button.border-black {
    background-color: transparent;
    border: 2px solid black;
    color: black;
    border-radius: 25px;
    padding: 10px 45px;
    cursor: pointer;
}

button.gradient {
    font-size: large;
    font-weight: 900;
    cursor: pointer;
    background-color: #E6F8FF;
    color: black;
    padding: 10px 45px;
    border-radius: 25px;
    border: none;
    outline: none;
    box-shadow:
        -6px -5px 20px rgba(0, 191, 255, 0.6),
        6px 5px 20px rgba(255, 223, 0, 0.6)
}

.scroll-container {
    width: 100%;
    height: 100%;
}

.top-bg {
    /* top: -50%; */
    width: 100%;
    position: relative;
    height: 100%;
}

.page-wrapper {
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.flex-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.padding-wrapper {
    width: 70%;
    margin: auto;
}

.space-div {
    margin-bottom: 1rem;
    display: block;
}

/* TEXT */
.black-title {
    color: black;
    font-size: 2.5rem;
    font-family: 'Averta-Bold';
}

.black-big-title {
    color: black;
    font-size: 3.3rem;
    font-family: 'Averta-Black';
}

.black-bold-title {
    color: black;
    font-size: 2.8rem;
    font-family: 'Averta-Black';
}

.black-big-text {
    color: black;
    font-size: 2.4rem;
    font-family: 'Averta-Regular';
}

.black-content-text {
    color: black;
    font-size: 1.5rem;
    padding: 0.3rem 0;
    font-family: 'Averta-Regular';
}

.white-title {
    color: white;
    font-size: 2.5rem;
    font-family: 'Averta-Bold';
}

.white-big-title {
    color: white;
    font-size: 3.3rem;
    font-family: 'Averta-Black';
}

.white-bold-title {
    color: white;
    font-size: 2.8rem;
    font-family: 'Averta-Black';
}

.white-big-text {
    color: white;
    font-size: 2.4rem;
    font-family: 'Averta-Regular';
}

.white-content-text {
    color: white;
    font-size: 1.5rem;
    padding: 0.3rem 0;
    font-family: 'Averta-Regular';
}

.line {
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    height: 2.5rem;
    /* Fixed height for slide-up effect */
}

.line.text-right {
    font-weight: bold;
    white-space: nowrap;
    margin-left: 1rem;
}

@import '../style/header.css';
@import '../style//home/index.css';
@import '../style//home/service.css';
@import '../style//home/topbanner.css';
@import '../style/responsive.css';
@import '../style/home/partner.css';
@import '../style/solution/header.css';
@import '../style/solution/footer.css';
@import '../style/footer.css';
@import '../style/about.css';
@import '../style/solution.css';
@import '../style/solution/vendingMarketing/index.css';
@import '../style/solution/vendingMarketing/priceCard.css';
@import '../style/solution/vendingMarketing/additionalService.css';
@import '../style/contact/contact.css';
@import '../style/solution/vendingMachine/vendingMachine.css'