.com-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 79px;
    max-height: 79px;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: #00162f91 !important;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.com-header::before {
    content: '';
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    height: 120px;
    background: radial-gradient(circle at center, rgba(36, 180, 225, 0.8) 0%, rgba(36, 180, 225, 0.2) 80%, transparent 100%);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    filter: blur(12px);
    pointer-events: none;
    z-index: -1;
}

.com-header label {
    cursor: pointer;
    user-select: none;
    color: white;
}

.com-header .navi-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    gap: 50px;
    font-weight: bold;
}

.com-header .navi-bar-mobile {
    display: none;
}

.com-header .navi-bar-mobile-content {
    width: 100%;
    background-color: rgb(47 71 98 / 99%);
    /* background-color: rgb(47 71 98 / 86%); */
    z-index: 100;
    opacity: 0;
    margin-top: 1.15rem !important;
}

.com-header .navi-bar-mobile-content label {
    color: white !important;
}

.com-header-overlay {
    background-color: rgba(0, 0, 0, 0.759);
    left: 0;
    top: 79px;
    z-index: 99;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

@media(max-width: 760px) {
    .com-header .navi-bar {
        gap: 30px;
    }
}

@media(max-width: 704px) {
    .com-header .navi-bar {
        gap: 20px;
    }
}

@media(max-width: 640px) {
    .com-header .navi-bar {
        gap: 10px;
    }
}

@media(max-width: 592px) {
    .com-header .navi-bar {
        display: none;
    }

    .com-header .navi-bar-mobile {
        display: flex;
        z-index: 100;
    }
}